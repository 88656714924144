/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import loginGqlService from '@modules/login/services/graphql';
import { removeIsLoginFlagging } from '@helper_auth';
import Cookies from 'js-cookie';
import { custDataNameCookie } from '@config';
import { useRouter } from 'next/router';
import { /* withStyles, */ makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
// import { getDocsGuide } from '@helper_config';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from '@i18n';
// import LanguageSelect from '@modules/theme/layout/components/languageSelect';
// import DrawerHelp from '@modules/theme/layout/components/drawerHelp';
import { FONT_ROBOTO } from '@theme_typography';
import {
    PRIMARY, BORDER_COLOR, PRIMARY_DARK, GRAY_700, GRAY_ARROW, GRAY_300,
} from '@theme_color';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        border: `1px solid ${GRAY_300}`,
        height: 30,
        width: 30,
        borderRadius: 4,
        '& .icon': {
            height: 20,
            width: 20,
            fill: PRIMARY_DARK,
        },
        [theme.breakpoints.down('xs')]: {
            height: 30,
            width: 30,
            '& .icon': {
                height: 16,
                width: 16,
            },
        },
    },
    lis: {
        margin: '0 4px',
        padding: 0,
        '&:hover': {
            '& .MuiIconButton-root': {
                backgroundColor: PRIMARY,
                '& .icon': {
                    filter: 'brightness(0%) invert(100%)',
                },
            },
        },
        '&:first-child': {
            [theme.breakpoints.up('md')]: {
                width: '100%',
                marginTop: 5,
                marginBottom: 5,
            },
            [theme.breakpoints.up('middleView')]: {
                width: 'auto',
            },
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0px 6px',
        },
    },
    notifTab: {
        display: 'flex',
        alignitems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${BORDER_COLOR}`,
        position: 'relative',
    },
    separator: {
        borderLeft: `1px solid ${GRAY_300}`,
        height: 18,
        margin: '0 8px',
    },
}));

// const StyledBadge = withStyles((theme) => ({
//     badge: {
//         padding: 4,
//         background: PRIMARY,
//         color: 'white',
//         borderRadius: '50%',
//         border: '1px solid white',
//         height: 20,
//         fontSize: 10,
//         [theme.breakpoints.down('xs')]: {
//             height: 18,
//         },
//     },
// }))(Badge);

// eslint-disable-next-line no-unused-vars
const RightToolbar = ({
    // notificationRes = null,
    // setLoadLang,
    openSearch, setOpenSearch,
}) => {
    const { t } = useTranslation('common');
    const router = useRouter();
    const classes = useStyles();
    const [removeCustomerToken] = loginGqlService.removeToken();
    const [getCustomer, getCustomerRes] = loginGqlService.getCustomer();
    const getCustomerFromGql = () => getCustomerRes && getCustomerRes.data && getCustomerRes.data.customer;
    const [username, setUsername] = React.useState('');
    // const [openHelp, setOpenHelp] = React.useState(false);
    const refOpenSearch = React.useRef(null);

    const handleSetUsername = (customer) => {
        const firstname = customer && customer.firstname;
        const lastname = customer && customer.lastname;
        setUsername(`${firstname} ${lastname}`);
    };

    const handleClickSearch = () => {
        setOpenSearch(!openSearch);
    };

    const handleOutsideClick = (e) => {
        if (refOpenSearch.current && !refOpenSearch.current.contains(e.target)) {
            setOpenSearch(false);
        }
    };
    React.useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    React.useEffect(() => {
        if (Cookies.getJSON(custDataNameCookie)) {
            handleSetUsername(Cookies.getJSON(custDataNameCookie));
        } else {
            getCustomer();
        }
    }, []);

    React.useEffect(() => {
        if (getCustomerFromGql()) {
            Cookies.set(custDataNameCookie, getCustomerFromGql());
            handleSetUsername(getCustomerFromGql());
        }
    }, [getCustomerFromGql()]);

    const handleLogout = () => {
        removeCustomerToken()
            .then(() => {
                removeIsLoginFlagging();
                Cookies.remove(custDataNameCookie);
                router.push('/login');
            })
            .catch(() => { });
    };

    // const { loading, data } = notificationRes;
    // const loading = false;
    // const data = null;

    return (
        <ul>
            <li className={classes.lis}>
                <Hidden mdUp>
                    <IconButton
                        id="guide-search-mobile"
                        className={classes.iconButton}
                        onClick={() => handleClickSearch()}
                        ref={refOpenSearch}
                    >
                        <img alt="logo-search" src="/assets/img/layout/search-mobile.svg" className="icon" />
                    </IconButton>
                </Hidden>
            </li>
            {/* <li className={classes.lis}>
                <IconButton className={classes.iconButton} style={{ padding: 0 }} color="inherit">
                    <StyledBadge
                        badgeContent={data?.getNotificationList?.total_count}
                        max={99}
                        overlap="circular"
                    >
                        <img alt="" src="/assets/img/layout/notification.svg" className="icon" />
                    </StyledBadge>
                </IconButton>
                <ul
                    style={{
                        width: 270,
                        left: -190,
                        border: `1px solid ${GRAY_300}`,
                        borderRadius: 8,
                    }}
                >
                    {loading ? (
                        <CircularProgress size={20} />
                    ) : (
                        <div>
                            <a className="viewMessage" href="/oms/notification">
                                <li style={{ textAlign: 'left', margin: '5px 0', padding: '5px 12px' }}>
                                    {t('common:You_have')}
                                    {' '}
                                    {data?.getNotificationList.total_count || 0}
                                    {' '}
                                    {t('common:unread_notifications')}
                                </li>
                            </a>
                            {data?.getNotificationList?.items?.map((notif, idx) => (
                                <li
                                    key={idx}
                                    style={{
                                        color: 'black',
                                        borderTop: '1px solid #B1BCDB',
                                        margin: 0,
                                        width: 250,
                                        textOverflow: 'ellipsis',
                                        padding: '5px 12px',
                                    }}
                                >
                                    <span style={{ color: '#B1BCDB', fontSize: 12 }}>{notif.created_at}</span>
                                    <br />
                                    <span style={{ color: '#435179', fontSize: 14, fontWeight: 'bold' }}>{notif.entity_type}</span>
                                    <br />
                                    <span
                                        style={{
                                            color: '#435179',
                                            fontSize: 12,
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {notif.message.slice(0, 30)}
                                        ...
                                    </span>
                                </li>
                            ))}
                        </div>
                    )}
                </ul>
            </li> */}
            {/* <li className={classes.lis}>
                <a
                    href={getDocsGuide()}
                    target="_blank"
                    rel="noreferrer"
                    // onClick={sendToGTM}
                >
                    <IconButton className={classes.iconButton}>
                        <img alt="" src="/assets/img/layout/icon_help_docs.svg" className="icon help" />
                    </IconButton>
                </a>
            </li> */}
            {/* <li className={classes.lis}>
                <a href="#">
                    <IconButton className={classes.iconButton} onClick={() => setOpenHelp(true)}>
                        <img alt="" src="/assets/img/layout/help.svg" className="icon" />
                    </IconButton>
                </a>
                <DrawerHelp openHelp={openHelp} setOpenHelp={setOpenHelp} />
            </li> */}
            {/* <Hidden smDown>
                <li className={classes.separator} />
                <li className={classes.lis}>
                    <LanguageSelect white setLoadLang={setLoadLang} />
                </li>
            </Hidden> */}
            <Hidden xsDown>
                {/* <li className={classes.separator} /> */}
                <li className={classes.lis}>
                    <a href="#">
                        <Hidden xsDown implementation="css">
                            {username}
                            <KeyboardArrowDownIcon
                                style={{
                                    verticalAlign: 'middle',
                                    marginLeft: 4,
                                    color: GRAY_ARROW,
                                    width: 18,
                                    height: 18,
                                }}
                            />
                        </Hidden>
                        <Hidden smUp implementation="css">
                            <AccountCircleOutlinedIcon style={{ transform: 'translateY(2px)', fontSize: 27 }} />
                        </Hidden>
                    </a>
                    <ul
                        style={{
                            border: `1px solid ${GRAY_300}`,
                            borderRadius: 8,
                        }}
                    >
                        {/* <li>
                            <a className="linkOut" href="/myaccount">
                                My Account
                            </a>
                        </li> */}
                        <li style={{ padding: '5px 12px' }}>
                            <a className="linkOut" href="#" onClick={handleLogout}>
                                {t('common:Sign_Out')}
                            </a>
                        </li>
                    </ul>
                </li>
            </Hidden>

            <style jsx>
                {`
                    ul {
                        margin: 0;
                        list-style: none;
                        padding: 0;
                        float: right;
                        font-size: 10px;
                        font-family: ${FONT_ROBOTO} !important;
                        position: fixed;
                        right: 12px;
                    }
                    li {
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                    }
                    li:hover > ul {
                        display: block;
                    }
                    ul ul {
                        position: absolute;
                        display: none;
                        margin: 0;
                        padding: 5px 10px;
                        z-index: 999;
                        background: #fff;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
                        right: 0;
                    }
                    ul ul li {
                        display: block;
                    }
                    ul ul ul {
                        position: absolute;
                        top: 0;
                        left: 100%;
                    }
                    a {
                        color: ${GRAY_700};
                        text-decoration: none;
                        white-space: nowrap;
                        font-size: 14px;
                    }
                    a:hover {
                        border-bottom: 1px dashed #fff;
                        color: ${GRAY_700};
                    }
                    .linkOut {
                        color: ${GRAY_700};
                    }
                    .viewMessage {
                        color: #be1f93;
                        font-size: 10px;
                    }
                `}
            </style>
        </ul>
    );
};
export default RightToolbar;
